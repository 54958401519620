import React from "react"

import { useImplementations } from "../hooks"
import { Contact, ImplementationDetails, Layout, SEO } from "../components"
import {
  Caption,
  H1,
  H2,
  MoreImplementationsTitle,
  MoreImplementationsWrapper,
} from "../elements"

const MoreImplementations = () => {
  const implementations = useImplementations()

  return (
    <Layout>
      <SEO title="Nasze renowacje łodzi i jachtów"></SEO>
      <MoreImplementationsTitle>
        <Caption>Realizacje</Caption>
        <H1>Nasze renowacje łodzi i jachtów</H1>
      </MoreImplementationsTitle>
      <MoreImplementationsWrapper>
        {implementations ? (
          implementations.map(implementation => (
            <ImplementationDetails
              key={implementation.id}
              title={implementation.title}
              description={implementation.description}
              images={implementation.images}
            ></ImplementationDetails>
          ))
        ) : (
          <H2>Tu pojawią się nasze realizacje.</H2>
        )}
      </MoreImplementationsWrapper>
      <Contact></Contact>
    </Layout>
  )
}

export default MoreImplementations
